import logo from '../../logo.png';
import './home.css';
import { faComments, faShoppingCart, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Home() {
    return (
        <div className="swipper-block home">
			<div className="bg-overlay" />
			
            <img src={logo} className="home-logo" alt="logo" />

			
			<div className="home-buttons">
				<div className="home-button" onClick={() => window.open('https://discord.gg/gtalife', '_blank')}>
					<FontAwesomeIcon icon={faComments} />
					<span className="title">Discord</span>
				</div>

				<div className="home-button" onClick={() => window.open('https://shop.glife.fr/', '_blank')}>
					<FontAwesomeIcon icon={faShoppingCart} />
					<span className="title">Donation</span>
				</div>

				<div className="home-button" onClick={() => window.open('https://www.youtube.com/c/GTALife', '_blank')}>
					<FontAwesomeIcon icon={faVideo} />
					<span className="title">Youtube</span>
				</div>
			</div>
        </div>
    );
}