import './App.css';
// import { AppHeader } from './components';
import { Home, AboutUs, Footer } from './views';

function App() {
	return (
		<div className="App">
			<div className="swipper-main">
				<Home />
				<AboutUs />
				<Footer />
			</div>
		</div>
	);
}

export default App;
